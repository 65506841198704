import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import order from "./order";
import analytics from "./analytics";
import catalog from "./catalog";

export default createStore({
  state: {
    restaurant: null,
    menus: [],
    languages: [],
    currencies: [],

    locale: "eng",
    themes: [],
    isBuiltInView: false,
    fontSizeCoefficientZoom: 1,
    isMenusFetched: false,
    addCurrencySymbolFunc: (value) => value,

    getRestaurantRequestData: null,
    getMenusRequestData: null,

    errorPageData: null,
    footerState: {
      pinned: null,
      unpinned: false,
    },
  },
  getters: {
    restaurant(state) {
      return state.restaurant;
    },
    menus(state) {
      return state.menus;
    },
    languages(state) {
      return state.languages;
    },
    currencies(state) {
      return state.currencies;
    },

    isPriceVisible(state) {
      return state.isMenusFetched && state.menus.length
        ? state.menus[0].isPriceVisible
        : true;
    },
    itemsAll(state) {
      return state.menus
        .flatMap((i) =>
          i.content.flatMap((j) => (j.type === "Category" ? j.content : [])),
        )
        .filter(
          (i) => i.type === "MenuItem" || i.type === "CombinationMenuItem",
        );
    },
    locale(state) {
      return state.locale;
    },
    VUE_APP_IMG_BASE() {
      return process.env.VUE_APP_IMG_BASE;
    },
    themes(state) {
      return state.themes;
    },
    isBuiltInView(state) {
      return state.isBuiltInView;
    },
    fontSizeCoefficientZoom(state) {
      return state.fontSizeCoefficientZoom;
    },
    isMenusFetched(state) {
      return state.isMenusFetched;
    },
    addCurrencySymbol(state) {
      return state.addCurrencySymbolFunc;
    },

    getRestaurantRequestData(state) {
      return state.getRestaurantRequestData;
    },
    getMenusRequestData(state) {
      return state.getMenusRequestData;
    },

    errorPageData(state) {
      return state.errorPageData;
    },
    footerState(state) {
      return state.footerState;
    },
  },
  mutations: {
    setRestaurant(state, data) {
      state.restaurant = data;
    },
    setMenus(state, data) {
      state.menus = data;
    },
    setLanguages(state, data) {
      state.languages = data;
    },
    setCurrencies(state, data) {
      state.currencies = data;
    },

    setLocale(state, data) {
      state.locale = data;
    },
    setThemes(state, data) {
      state.themes = data;
    },
    setIsBuiltInView(state, data) {
      state.isBuiltInView = data;
    },
    setFontSizeCoefficientZoom(state, data) {
      state.fontSizeCoefficientZoom = data;
    },
    setIsMenusFetched(state, data) {
      state.isMenusFetched = data;
    },
    setAddCurrencySymbolFunc(state, data) {
      state.addCurrencySymbolFunc = data;
    },

    setGetRestaurantRequestData(state, data) {
      state.getRestaurantRequestData = data;
    },
    setGetMenusRequestData(state, data) {
      state.getMenusRequestData = data;
    },

    setErrorPageData(state, data) {
      state.errorPageData = data;
    },
    setFooterState(state, data) {
      state.footerState = data;
    },
  },
  actions: {
    setRestaurant({ commit }, data) {
      commit("setRestaurant", data);
    },
    setMenus({ commit, rootGetters, dispatch }, data) {
      let result = structuredClone(data).map((i) => {
        const { categories, content: contentMenu, ...restMenu } = i;
        return {
          ...restMenu,
          content: contentMenu.map((j) => {
            if (j.type === "Category") {
              const {
                items,
                content: contentCategory,
                ...restCategory
              } = categories.find((k) => k.id === j.id);
              const itemsFiltered = items
                .filter((k) => k.isVisible)
                .map((k) => ({
                  ...k,
                  options: k.options
                    .map((m) => ({
                      ...m,
                      items: m.items.filter((n) => n.isVisible),
                    }))
                    .filter((m) =>
                      m.type === "choose"
                        ? m.items.length && m.items.find((n) => n.isEnabled)
                        : m.items.length,
                    ),
                }));
              return {
                ...restCategory,
                content: contentCategory
                  .map((k) =>
                    k.type === "Offer"
                      ? itemsFiltered.find((m) => m.id === k.id)
                      : k,
                  )
                  .filter((k) => !!k),
                type: "Category",
              };
            }
            return j;
          }),
        };
      });

      const itemsAll = result
        .flatMap((i) =>
          i.content.flatMap((j) => (j.type === "Category" ? j.content : [])),
        )
        .filter(
          (i) => i.type === "MenuItem" || i.type === "CombinationMenuItem",
        );

      result = result.map((i) => {
        return {
          ...i,
          content: i.content.map((j) => {
            if (j.type === "Category") {
              return {
                ...j,
                content: j.content.map((k) => {
                  if (
                    k.type === "MenuItem" ||
                    k.type === "CombinationMenuItem"
                  ) {
                    return {
                      ...k,
                      extras: k.extras
                        .map((m) => ({
                          ...m,
                          items: m.items
                            .filter((n) =>
                              n.type === "SimpleItem"
                                ? n.isVisible
                                : itemsAll.find(
                                    (v) => v.originalMenuItemId === n.id,
                                  ),
                            )
                            .map((n) =>
                              n.type === "SimpleItem"
                                ? n
                                : {
                                    ...itemsAll.find(
                                      (v) => v.originalMenuItemId === n.id,
                                    ),
                                    addPrice: n.addPrice,
                                  },
                            ),
                        }))
                        .filter((m) => m.name && m.items.length),
                      suggestionItems: k.suggestionItems
                        .map((m) =>
                          itemsAll.find((n) => n.originalMenuItemId === m.id),
                        )
                        .filter((m) => !!m),
                    };
                  }
                  return k;
                }),
              };
            }
            return j;
          }),
        };
      });

      commit("setMenus", result);
      commit("setIsMenusFetched", true);

      const orderItemsToDelete = rootGetters["order/items"].filter(
        (orderItem) => {
          const item = itemsAll.find((i) => i.id === orderItem.offerId);
          if (!item || !item.isEnabled) {
            return true;
          }

          const notValidOptions = orderItem.options.filter((i) => {
            const option = item.options.find((j) =>
              j.items.find((k) => k.id === i),
            );
            if (!option) {
              return true;
            }

            const optionItem = option.items.find((j) => j.id === i);
            if (!optionItem || !optionItem.isEnabled) {
              return true;
            }
            return false;
          });
          if (notValidOptions.length) {
            return true;
          }

          const notValidExtras = orderItem.extras.filter((i) => {
            const extrasItems = item.extras.flatMap((j) => j.items);
            const extraItem = extrasItems.find((j) => j.id === i);
            if (!extraItem) {
              return true;
            }
            if (extraItem.type === "SimpleItem") {
              return !extraItem.isEnabled;
            }
            return !itemsAll.find((j) => j.originalMenuItemId === extraItem.id)
              .isEnabled;
          });
          if (notValidExtras.length) {
            return true;
          }

          return false;
        },
      );
      orderItemsToDelete.forEach((i) => {
        dispatch("order/removeOrderItem", i.id, { root: true });
      });
    },
    setLanguages({ commit }, data) {
      commit("setLanguages", data);
    },
    setCurrencies({ commit }, data) {
      commit("setCurrencies", data);
    },

    setLocale({ commit }, data) {
      commit("setLocale", data);
    },
    setTheme({ commit, getters }, data) {
      const theme = getters.themes.find((i) => i.restId === data.restId);
      if (theme) {
        commit("setThemes", [
          ...getters.themes.filter((i) => i.restId !== theme.restId),
          data,
        ]);
      } else {
        commit("setThemes", [...getters.themes, data]);
      }
    },
    setIsBuiltInView({ commit }, data) {
      commit("setIsBuiltInView", data);
    },
    setFontSizeCoefficientZoom({ commit }, data) {
      commit("setFontSizeCoefficientZoom", data);
    },
    setAddCurrencySymbolFunc({ commit, state }) {
      if (!state.restaurant.showCurrencySymbol) {
        return;
      }

      const currency = state.currencies.find(
        (i) => i.code === state.restaurant.primaryCurrency,
      );
      const currencySymbol = currency ? currency.symbolNative : "";
      if (!currencySymbol) {
        return;
      }

      // u2009 is &thinsp;
      const result = state.restaurant.showCurrencySymbolInFront
        ? (value) => `${currencySymbol}\u2009${value}`
        : (value) => `${value}\u2009${currencySymbol}`;
      commit("setAddCurrencySymbolFunc", result);
    },

    setGetRestaurantRequestData({ commit }, data) {
      commit("setGetRestaurantRequestData", data);
    },
    setGetMenusRequestData({ commit }, data) {
      commit("setGetMenusRequestData", data);
    },

    setErrorPageData({ commit }, data) {
      commit("setErrorPageData", structuredClone(data));
    },
    setFooterState({ commit }, data) {
      commit("setFooterState", data);
    },
  },
  modules: {
    order,
    analytics,
    catalog,
  },
  plugins: [
    createPersistedState({
      key: "spork_client",
      paths: [
        "locale",
        "fontSizeCoefficientZoom",
        "order.items",
        "analytics",
        "themes",
      ],
    }),
  ],
});
